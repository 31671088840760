<template>
  <div ref="mixedChecker" :style="slidePosition" class="h-100 z-2 mixedChecker">
    <img src="images/elements-grid-2017.svg" class="position-absolute w-100 h-auto" aria-label="Table elements"
      width="174" height="163" style="left:-10%;bottom: -100%;" />
  </div>
</template>

<script>
import anime from "animejs"
import { normalize } from "@/utils/ranges"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      let response = slidePositionPlain(this.globalUnits, this.slideIndex)
      response.width = "160%"
      return response
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() { },
  methods: {
    mixedChecker(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 200
      })

      animation.add({
        targets: ".mixedChecker",
        opacity: [0, 1],
        duration: 600,
        delay: 0,
        easing: "easeInOutSine"
      })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
      //animation.seek(animation.duration * percentage)
    }
  },
  watch: {
    innerPosition() {
      this.mixedChecker(this.innerPosition)
    }
  }
}
</script>
<style lang="scss" scoped></style>
